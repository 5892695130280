import {JSONSchema7} from 'json-schema';

export const CUSTOMER_PAYMENT_SCHEMA: JSONSchema7 = {
	properties: {
		token: {
			type: 'string',
			description: `The tokenex token that can be used to lookup customer payment information.`
		}
	},
	required: [
		'token'
	]
}