import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { StateService } from '../services/state.service';

export function permissionsGuard(): CanActivateFn {
  return () => {
    const state: StateService = inject(StateService);
    return state.setPermissions();
  };
}
