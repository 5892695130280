import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  collectionGroup as collectionGroupFn,
  deleteDoc,
  doc,
  docData,
  Firestore,
  getCountFromServer,
  getDoc,
  getDocs,
  getDocsFromCache,
  getDocsFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
  writeBatch,
} from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { FilterMethod, SHARED_CONFIG } from '@definitions';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { WhereFilter } from '../types/where-filter.interface';

@Injectable({ providedIn: 'root' })
export class DbService {
  constructor(
    private firestore: Firestore,
    private functions: Functions
  ) {}

  url(url: string) {
    return `https://${SHARED_CONFIG.cloudRegion}-${environment.firebase.projectId}.cloudfunctions.net/${url}`;
  }

  getDocuments(
    moduleId: string,
    pageSize: number,
    sort?:
      | Array<{ active: string; direction: 'asc' | 'desc' }>
      | { active: string; direction: 'asc' | 'desc' },
    cursor?: any,
    filters?: any[],
    source?: 'server' | 'cache',
    page?: number
  ): Observable<any[]> {
    const sources = {
      server: getDocsFromServer,
      cache: getDocsFromCache,
    };
    const method = source ? sources[source] : getDocs;

    return this.collection(
      moduleId,
      pageSize,
      sort,
      cursor,
      filters,
      page
    ).pipe(
      switchMap(query => method(query)),
      map((res: any) => res.docs),
      catchError(e => {
        console.error(e);
        return of([]);
      })
    );
  }

  getCount(moduleId: string, sort?, filters?: WhereFilter[]) {
    return this.collection(moduleId, undefined, sort, undefined, filters).pipe(
      switchMap(query => getCountFromServer(query)),
      map(doc => doc.data().count)
    );
  }

  getStateChanges(
    moduleId,
    pageSize?,
    sort?,
    cursor?,
    filters?: WhereFilter[],
    page?
  ) {
    return this.collection(
      moduleId,
      pageSize,
      sort,
      cursor,
      filters,
      page
    ).pipe(
      switchMap(
        query =>
          new Observable(observer =>
            onSnapshot(query, snap => {
              const docs = snap.docChanges();
              if (docs.length) {
                observer.next(docs);
              }
            })
          ) as Observable<any>
      )
    );
  }

  getValueChanges(
    moduleId,
    pageSize?,
    sort?,
    cursor?,
    filters?: WhereFilter[],
    collectionGroup?
  ) {
    return this.collection(
      moduleId,
      pageSize,
      sort,
      cursor,
      filters,
      collectionGroup
    ).pipe(
      switchMap(query =>
        collectionData(query, {
          idField: 'id',
        })
      )
    );
  }

  getDocument<T = any>(moduleId, documentId, stream = false): Observable<T> {
    if (stream) {
      return docData<T>(doc(this.firestore, moduleId, documentId) as any, {
        idField: 'id',
      });
    }

    return from(getDoc(doc(this.firestore, moduleId, documentId))).pipe(
      map(
        snap =>
          ({
            ...snap.data(),
            id: documentId,
          }) as any
      )
    );
  }

  getDocumentsSimple(moduleId: string, sort?: string, filter?: WhereFilter) {
    return from(
      getDocs(
        query(
          collection(this.firestore, moduleId),
          ...[
            sort && orderBy(sort, 'asc'),
            filter && where(filter.key, filter.operator, filter.value),
          ].filter(it => it)
        )
      )
    ).pipe(
      map(data =>
        data.docs.map((it: any) => ({
          id: it.id,
          ...it.data(),
        }))
      ),
      catchError(error => {
        console.error(error);
        return of([]);
      })
    );
  }

  getSubdocumentsSimple(moduleId, sort?, filter?) {
    return from(
      getDocs(
        query(
          collectionGroupFn(this.firestore, moduleId),
          ...[
            sort && orderBy(sort.active, sort.direction),
            filter && where(filter.key, filter.operator, filter.value),
          ].filter(it => it)
        )
      )
    ).pipe(
      map(data =>
        data.docs.map((it: any) => ({
          id: it.id,
          ...it.data(),
        }))
      ),
      catchError(() => of([]))
    );
  }

  getDocumentSubcollection(path: string) {
    return from(getDocs(collection(this.firestore, path))).pipe(
      map(data =>
        data.docs.map((it: any) => ({
          id: it.id,
          ...it.data(),
        }))
      ),
      catchError(() => of([]))
    );
  }

  setDocument(
    moduleId: string,
    documentId: string,
    data: any,
    options?: { merge: boolean }
  ) {
    return from(
      setDoc(doc(this.firestore, moduleId, documentId), data, options || {})
    );
  }

  async setDocumentWithSubCollection(
    moduleId: string,
    documentId: string,
    data: any,
    subCollection: string,
    subDocsWithIds: { subDocId: string; subDocData: any }[]
  ) {
    const batch = writeBatch(this.firestore);
    const docRef = doc(this.firestore, moduleId, documentId);
    batch.set(docRef, data, { merge: true });

    const subCollectionRef = collection(
      this.firestore,
      `${moduleId}/${documentId}/${subCollection}`
    );
    const snapshot = await getDocs(subCollectionRef);
    snapshot.docs.forEach(subDoc => {
      batch.delete(subDoc.ref);
    });

    subDocsWithIds.forEach(({ subDocId, subDocData }) => {
      const subDocRef = doc(
        this.firestore,
        `${moduleId}/${documentId}/${subCollection}`,
        subDocId
      );
      batch.set(subDocRef, subDocData);
    });

    return from(batch.commit());
  }

  removeDocument(moduleId, documentId) {
    return from(deleteDoc(doc(this.firestore, moduleId, documentId)));
  }

  createUserAccount(
    email: string,
    password: string
  ): Observable<{ uid: string }> {
    return this.callFunction('rest-createuser', { email, password }) as any;
  }

  resetPassword(
    id: string,
    password: string,
    email: string,
    group: string,
    firstName: string
  ) {
    return this.callFunction('rest-resetpassword', {
      id,
      password,
      email,
      group,
      firstName,
    });
  }

  createCustomerAccount(
    email: string,
    password: string,
    phoneNumber: string
  ): Observable<{ uid: string }> {
    return this.callFunction('rest-createcustomer', {
      email,
      password,
      phoneNumber,
    }) as any;
  }

  removeUserAccount(id: string) {
    return this.callFunction('cmsremoveUser', { id });
  }

  checkIfUserExists(email: string): Observable<{ uid: string }> {
    return this.callFunction('rest-checkifuserexists', { email }) as any;
  }

  callFunction(name: string, data: any) {
    return httpsCallableData(this.functions, name)(data);
  }

  private collection(
    moduleId: string,
    pageSize: number,
    sort:
      | Array<{ active: string; direction: 'asc' | 'desc' }>
      | { active: string; direction: 'asc' | 'desc' },
    cursor?: any,
    filters?: any[],
    page?: number
  ) {
    if (Array.isArray(sort)) {
      sort = [...sort];
    }

    /**
     * In firebase we can't sort by the
     * key we filter with.
     */
    if (
      filters?.length &&
      (Array.isArray(sort) || sort?.active) &&
      filters.some(it =>
        Array.isArray(sort)
          ? sort.some(s => s.active === it.key && s.active !== 'createdOn')
          : it.key === sort.active && sort.active !== 'createdOn'
      )
    ) {
      sort = null;
    }

    const methods = [
      ...(sort
        ? Array.isArray(sort)
          ? sort.map(it => orderBy(it.active, it.direction))
          : [orderBy(sort.active, sort.direction)]
        : []),
      ...this.filterMethod(filters || []),
    ].filter(it => it);

    if (page) {
      return this.callFunction('callable-cursor', {
        module: moduleId,
        group: filters.find(f => f.key === 'group').value,
        page: page + 1,
        sort,
        pageSize,
        filters,
      }).pipe(
        switchMap((docId: string) =>
          from(getDoc(doc(this.firestore, moduleId, docId)))
        ),
        switchMap(d => {
          methods.push(startAfter(d));

          if (pageSize) {
            methods.push(limit(pageSize));
          }

          return of(query(collection(this.firestore, moduleId), ...methods));
        })
      );
    }

    if (pageSize) {
      methods.push(limit(pageSize));
    }

    return of(query(collection(this.firestore, moduleId), ...methods));
  }

  private filterMethod(filters?: WhereFilter[]) {
    if (filters) {
      return filters.reduce((acc, item) => {
        if (
          item.skipFalsyValueCheck ||
          (item.value !== undefined &&
            item.value !== null &&
            !Number.isNaN(item.value) &&
            item.value !== '' &&
            ((item.operator === FilterMethod.ArrayContains ||
              item.operator === FilterMethod.ArrayContainsAny ||
              item.operator === FilterMethod.In) &&
            Array.isArray(item.value)
              ? item.value.length
              : true))
        ) {
          acc.push(where(item.key, item.operator, item.value));
        }

        return acc;
      }, []);
    }

    return [];
  }
}
