import { inject } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SavingsCalculation } from '../types/savings-calculation.interface';

export const SavingsCalculatorResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  firestore: Firestore = inject(Firestore),
  router: Router = inject(Router)
): Observable<SavingsCalculation> =>
  from(getDoc(doc(firestore, 'savings-calculations', route.params['id']))).pipe(
    switchMap(doc => {
      if (doc.exists) {
        return of(doc.data() as SavingsCalculation);
      }

      return throwError(() => new Error('Savings calculation not found'));
    }),
    catchError(() => {
      router.navigate(['/login']);
      return of(null);
    })
  );
