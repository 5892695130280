import {JSONSchema7} from 'json-schema';

export const BENEFIT_REDEMPTIONS_SCHEMA: JSONSchema7 = {
	properties: {
		benefitPlan: {
			type: 'string',
			description: `The benefit plan id that was redeemed.`
		},
		location: {
			type: 'string',
			description: `The location id where the benefit was redeemed.`
		},
		vehicle: {
			type: 'string',
			description: `The vehicle id from for the associated vehicle record`
		},
		createdOn: {
			type: 'number',
			description: `The date and time that the vehicle was created.`
		},
		benefit: {
			type: 'string',
			description: `The benefits redeemed during this redemption.`
		},
	},
	required: [
		'benefitPlan',
		'location',
		'vehicle',
		'createdOn',
		'benefit',
	]
};