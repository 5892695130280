import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(module => {
    /**
     * Gives us a global reference to the injector
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).rootInjector = module.injector;
  })
  .catch(err => console.error(err));
