export const BENEFIT_PLANS_SCHEMA = {
	properties: {
		id: {
			type: 'string',
			description: 'A unique identifier for the benefit plan.'
		},
		group: {
			type: 'string',
			description: 'The group id that the benefit plan is configured for.'
		},
		location: {
			type: 'string',
			description: 'The location id where the benefit plan was created.'
		},
		name: {
			type: 'string',
			description: 'The name of the benefit plan.'
		},
		description: {
			type: 'string',
			description: 'A short description of the benefit plan.'
		},
		createdOn: {
			type: 'number',
			description: 'The date and time that the benefit plan was created.'
		},
		status: {
			type: 'string',
			description: 'The status of the benefit plan. IE Draft/Active'
		},
		planBasePrice: {
			type: 'number',
			description: 'The base price of the plan.'
		},
		termsAndConditions: {
			type: 'array',
			items: {
				type: 'string'
			},
			description: 'A file containing the terms and conditions of the benefit plan.'
		},
		isAllLocations: {
			type: 'boolean',
			description: 'If true, the plan is available to all locations in an auto group.'
		},
		locations: {
			type: 'array',
			items: {
				type: 'string'
			},
			description: 'An array of strings containing the locations where the benefit plan is valid.'
		},
		activationDate: {
			type: 'number',
			description: 'The date and time that the benefit plan was activated.'
		},
		services: {
			type: 'array',
			'items': {
				type: 'string'
			},
			description: 'An array of strings containing the services in the Benefit Plan.'
		},
		scheduledDate: {
			type: 'number',
			description: 'The date and time that the benefit plan is scheduled to be published.'
		},
		isUpgrade: {
			type: 'boolean',
			description: 'If true, the plan record is an upgrade, and should be attached to a benefit plan.'
		},
		benefitPlans: {
			type: 'array',
			items: {
				type: 'string'
			},
			description: 'An array of benefit plans the upgrade is attached to.'
		},
		isAllBenefitPlans: {
			type: 'boolean',
			description: 'If true, the upgrade plan is attached to all benefit plans.'
		}
	},
	required: ['id', 'group', 'location', 'name', 'createdOn', 'status', 'planBasePrice', 'locations', 'services']
}