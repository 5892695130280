import JSX from '../../jsx.compiler';
import {PipeType} from '../../types/enums/pipe-type.enum';

export const CUSTOMERS_LAYOUT = {
	table: {
		tableColumns: [
			{
				key: '/firstName',
				label: 'NAME',
				pipe: [PipeType.Custom],
				pipeArguments: {0: (value, row) => `${row.firstName} ${row.lastName}`},
			},
			{
				key: '/mobileNumber',
				label: 'PHONE',
				pipe: [PipeType.MobileNumber],
			},
			{
				key: '/vehicles',
				label: 'SERVICE_LOCATION',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: value => JSX(<div>
						{value.map(it => <div>{it.location?.name || '-'}</div>).join('')}
					</div>)
				}
			},
			{
				key: '/vehicles',
				label: 'PLANS',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: value => JSX(<div>
						{value.map(it => <div>{it.benefitPlan.map(i => i.name).join(', ') || '-'}</div>).join('')}
					</div>)
				}
			},
			{
				key: '/vehicles',
				label: 'UPGRADE',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: value => (
						JSX.createElement('div', {}, 
							...value.map(it => (
								JSX.createElement('div', { key: it.id }, 
									...it.benefitPlan.map(plan => 
										plan.upgrade ? JSX.createElement('app-e-checkbox', { checked: 'true', key: plan.id }) : ''
									)
								)
							))
						)
					)
				}
			},
			{
				key: '/vehicles',
				label: 'VEHICLE',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: value => JSX(<div>
						{value.map(it => <div>{[it.year, it.make, it.model].filter(Boolean).join(' ')}</div>).join('')}
					</div>)
				}
			},
			{
				key: '/vehicles',
				label: 'LICENSE',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: value => JSX(<div>
						{value.map(it => <div>{it.licensePlate || '-'}</div>).join('')}
					</div>)
				}
			},
			{
				key: '/vehicles',
				label: 'VIN',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: value => JSX(<div>
						{value.map(it => <div>{it.VIN ? it.VIN.slice(9).padStart(17, '*') : '-'}</div>).join('')}
					</div>)
				}
			},
		]
	}
}