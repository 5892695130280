import {JSONSchema7} from 'json-schema';

export const VEHICLES_SCHEMA: JSONSchema7 = {
	properties: {
		group: {
			type: 'string',
			description: `The ID of the group associated with the vehicles location ID.`
		},
		location: {
			type: 'string',
			description: `The ID of the primary location for the vehicle.`
		},
		customer: {
			type: 'string',
			description: `The ID of the customer who owns the vehicle.`
		},
		VIN: {
			type: 'string',
			description: `A unique identifier for the vehicle.`
		},
		year: {
			type: 'number',
			description: `The year that the vehicle was manufactured.`
		},
		make: {
			type: 'string',
			description: `The make of the vehicle.`
		},
		model: {
			type: 'string',
			description: `The model of the vehicle.`
		},
		color: {
			type: 'string',
			description: `The color of the vehicle.`
		},
		licensePlate: {
			type: 'string',
			description: `The license plate number of the vehicle.`
		},
		benefitPlans: {
			type: 'array',
			description: `The benefit plans that the vehicle is covered by.`
		},
		nextScheduledSvc: {
			type: 'number',
			description: `The date of the vehicle's next scheduled service.`
		}
	},
	required: [
		'group',
		'location',
		'customer',
		'VIN',
		'year',
		'make',
		'model',
		'color',
		'licensePlate',
		'benefitPlans',
		'nextScheduledSvc',
		'createdOn',
	]
}