import {JSONSchema7} from 'json-schema';

export const AUTO_GROUP_ADDRESS_SCHEMA: JSONSchema7 = {
	properties: {
    address: {
      type: 'string',
      description: 'The address of the auto group.'
    },
    city: {
      type: 'string',
      description: 'The city of the auto group.'
    },
    state: {
      type: 'string',
      description: 'The state of the auto group.'
    },
    zip: {
      type: 'string',
      description: 'The zip code of the auto group.'
    },
    country: {
      type: 'string',
      description: 'The country of the auto group.'
    },
    province: {
      type: 'string',
      description: 'The province of the auto group if in Canada.'
    }
  },
  required: ['address', 'city', 'state', 'zip', 'country']
}