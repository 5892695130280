import {JSONSchema7} from 'json-schema';

export const BENEFIT_PLAN_DESCRIPTIONS_SCHEMA: JSONSchema7 = {
	properties: {
		benefitPlan: {
			type: 'string',
			description: `The benefit plan id that the vehicle is covered by.`
		},
		location: {
			type: 'string',
			description: `The location id associated with the benefit plan.`
		},
		vehicle: {
			type: 'string',
			description: `The vehicle id from for the associated vehicle record`
		},
		activatedOn: {
			type: 'number',
			description: `The date and time that the vehicle was activated.`
		},
		cancelledOn: {
			type: 'number',
			description: `The date and time that the vehicle was cancelled.`
		},
	},
	required: [
		'benefitPlan',
		'location',
		'vehicle',
		'activatedOn',
		'cancelledOn',
	]
};