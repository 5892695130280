import JSX from '../../jsx.compiler';
import {ModuleLayout} from '../../types/module-layout.interface';
import {PipeType} from '../../types/types';

export const CAMPAIGNS_LAYOUT: ModuleLayout = {
	table: {
		tableColumns: [
			{
				key: '/title',
				label: 'TITLE'
			},
			{
				key: '/methodTypes',
				label: 'METHOD',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: v => JSX(<span class="uppercase">{v.map(it => it).join(', ')}</span>)
				}
			},
			{
				key: '/campaignType',
				label: 'TYPE',
				pipe: [
					PipeType.Transloco
				]
			},
			{
				key: '/status',
				label: 'STATUS',
				pipe: [
					PipeType.Custom,
					PipeType.Sanitize
				],
				pipeArguments: {
					0: v => JSX(<app-e-status status={v} />)
				}
			},
		]
	}
};