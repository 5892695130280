export const STAFF_ROLES_SCHEMA = {
    properties: {
        group: {
            type: 'string',
            description: 'The document id of the group the staff member belongs to.'
        },
        location: {
            type: 'string',
            description: 'The document id of the location the staff member belongs to.'
        },
        landingPage: {
            type: 'string',
            description: 'The landing page for the staff member.'
        },
        role: {
            type: 'string',
            description: 'The name of the role.'
        },
        permissions: {
            type: 'array',
            items: {
                type: 'string'
            },
            description: 'The permissions for the role.'
        }
    },
    required: [
        'group',
        'location'
    ]
}