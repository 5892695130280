import {JSONSchema7} from 'json-schema';

export const SETTINGS_BENEFITS_SCHEMA: JSONSchema7 = {
	properties: {
		serviceTypes: {
			type: 'array',
			minItems: 1
		},
		benefitPack: {
			type: 'number',
			minimum: 0,
			maximum: 100000
		}
	},
	required: [
		'serviceTypes',
		'benefitPack'
	]
};