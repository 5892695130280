export const LOCATIONS_SCHEMA = {
	properties: {
		id: {type: 'string'},
		group: {type: 'string'},
		name: {type: 'string'},
		createdOn: {type: 'number'},
		phoneNumber: {type: 'string'},
		oem: {type: 'string'},
		termsAndConditions: {type: 'string'},
		emailSettings: {
			type: 'object',
			properties: {
				replyToEmail: {type: 'string'},
				senderName: {type: 'string'},
			}
		}
	},
	required: [
		'id',
		'group',
		'name',
		'createdOn',
		'phoneNumber',
		'oem',
		'termsAndConditions'
	]
}