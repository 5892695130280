import {JSONSchema7} from 'json-schema';

export const SETTINGS_GROUP_DEFAULTS_SCHEMA: JSONSchema7 = {
	properties: {
		systemOfMeasurement: {
			type: 'string',
			enum: [
				'imperial',
				'metric'
			]
		},
		termsAndConditions: {
			type: 'string'
		},
		dateFormat: {
			type: 'string',
			enum: [
				'MM/DD/YYYY',
				'DD/MM/YYYY'
			]
		},
		timeFormat: {
			type: 'number',
			enum: [
				12,
				24
			]
		}
	},
	required: [
		'systemOfMeasurement',
		'termsAndConditions',
		'dateFormat',
		'timeFormat'
	]
};