import {ModuleLayout} from '../../types/module-layout.interface';
import {PipeType} from '../../types/enums/pipe-type.enum'
import JSX from "../../jsx.compiler";

export const STAFF_LAYOUT: ModuleLayout = {
	table: {
		tableColumns: [
			{
				key: '/firstName', label: 'NAME',
				pipe: [PipeType.Custom],
				pipeArguments: {0: (value, row) => `${row.firstName} ${row.lastName}`},
			},
			{
				key: '/email', label: 'EMAIL',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {0: (value) => JSX(<a href={'mailto:' + value}>{value}</a>)},
			},
			{key: '/role', label: 'ROLE'},
			{
				key: '/location', label: 'LOCATION',
				populate: {
					collection: 'locations',
					displayKey: 'name',
				}
			},
			{
				key: '/advisorAppAccess',
				label: 'ADVISOR_APP_ACCESS',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: value => JSX(<div class="center">{value ? JSX(<app-e-checkbox checked={true} />) : JSX(<div class="spacer" />)}</div>)
				}
			},
			{
				key: '/status',
				label: 'STATUS',
				pipe: [
					PipeType.Custom,
					PipeType.Sanitize
				],
				pipeArguments: {
					0: v => JSX(<app-e-status status={v} />)
				}
			},
		]
	}
}