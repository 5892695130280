import { inject } from '@angular/core';
import { Auth, verifyPasswordResetCode } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { catchError, from, map, of } from 'rxjs';

export function hasCodeGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot) => {
    const auth: Auth = inject(Auth);
    const router: Router = inject(Router);
    const snackBar: MatSnackBar = inject(MatSnackBar);

    const { oobCode } = route.queryParams;

    if (oobCode) {
      return from(verifyPasswordResetCode(auth, oobCode)).pipe(
        map(() => true),
        catchError(() => {
          snackBar.open('Invalid action code.', 'Dismiss', {
            panelClass: 'snack-bar-error',
            duration: 5000,
          });

          router.navigate(['/login']);

          return of(true);
        })
      );
    }

    router.navigate(['/login']);

    return of(false);
  };
}
