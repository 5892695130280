export const PROMOTIONS_SCHEMA = {
	properties: {
		id: {
			type: 'string',
			description: 'A unique identifier for the promotion.'
		},
		group: {
			type: 'string',
			description: 'The group that the promotion belongs to.'
		},
		name: {
			type: 'string',
			description: 'The name of the promotion.'
		},
		description: {
			type: 'string',
			description: `The description of the promotion.`
		},
		createdOn: {
			type: 'number',
			description: 'The date and time the promotion was created.'
		},
		location: {
			type: 'string',
			description: 'The location that the promotion is associated with.'
		},
		benefitPlans: {
			type: 'array',
			items: {
				type: 'string'
			},
			description: 'The benefit plans that the promotion is applicable to.'
		},
		customStartDate: {
			type: 'number',
			description: 'The start date of the promotion, whether benefit plan activation date or custom start date.'
		},
		isPlanStartDate: {
			type: 'boolean',
			description: `Whether to use the benefit plan start date to indicate when the promotion should start.`
		},
		discount: {
			type: 'number',
			description: 'The discount amount for the promotion.'
		},
		discountType: {
			type: 'string',
			description: 'The type of discount for the promotion. Percentage or Dollar amount.'
		},
		promoCode: {
			type: 'string',
			description: 'The promo code for the promotion.'
		},
		status: {
			type: 'string',
			description: 'The status of the promotion.',
			enum: [
				'active',
				'draft',
				'completed'
			]
		},
		isAllBenefits: {
			type: 'boolean',
			description: 'Whether the promotion is applied to all benefit plans.'
		},
		expiresDays: {
			type: 'number',
			description: 'The number of days the promotion expires after the start date.'
		},
		expiresCustomEnd: {
			type: 'number',
			description: 'The end date of the promotion\'s custom expiration period.'
		},
		isNeverExpire: {
			type: 'boolean',
			description: 'Whether the promotion never expires.'
		},
		isEmployees: {
			type: 'boolean',
			description: 'Whether the promotion applies to employees.'
		},
		isMembers: {
			type: 'boolean',
			description: 'Whether the promotion applies to members.'
		}
	},
	required: [
		'id',
		'group',
		'name',
		'createdOn',
		'isPlanStartDate',
		'discount',
		'discountType',
		'promoCode',
		'status',
		'isNeverExpires',
		'isEmployees',
		'isMembers'
	]
}