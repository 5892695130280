import {JSONSchema7} from 'json-schema';

export const AUTO_GROUP_BRAND_SCHEMA: JSONSchema7 = {
	properties: {
    logo: {
      type: 'string',
      description: 'The logo for the auto group brand.'
    },
    primaryColor: {
      type: 'string',
      description: 'The primary color for the auto group brand.'
    },
    mobileBg: {
      type: 'string',
      description: 'The mobile background for the auto group brand.'
    },
    slogan: {
      type: 'string',
      description: 'The slogan of the auto group.'
    }
  },
  required: ['logo', 'primaryColor', 'mobileBg']
}