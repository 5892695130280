export const SERVICES_SCHEMA = {
	properties: {
		id: {
			type: 'string',
			description: 'A unique identifier for the vehicle service.'
		},
		group: {
			type: 'string',
			description: 'The group that the vehicle service is configured for.'
		},
		location: {
			type: 'string',
			description: 'The location id where the vehicle service was created.'
		},
		benefitPlan: {
			type: 'array',
			'items': {
				type: 'string'
			},
			description: 'The benefit plan that the vehicle service is associated with.'
		},
		title: {
			type: 'string',
			description: 'The title of the vehicle service.'
		},
		createdOn: {
			type: 'number',
			description: 'The date and time that the vehicle service was created.'
		},
		serviceType: {
			type: 'string',
			description: 'The id of the group service type to categorize the service.'
		},
		startDate: {
			type: 'number',
			description: 'The start date of the vehicle service.'
		},
		endDate: {
			type: 'number',
			description: 'The end date of the vehicle service.'
		},
		description: {
			type: 'string',
			description: 'A description of the vehicle service.'
		},
		discount: {
			type: 'number',
			description: 'The discount that is vehicle serviceed by the vehicle service.'
		},
		discountType: {
			type: 'string',
			description: 'The type of discount that is vehicle serviceed by the vehicle service. Percentage or dollar amount.'
		},
		claimAmount: {
			type: 'number',
			description: 'The amount that will be re-imbursed to the VMB (service associate app facing only).'
		},
		autoRenew: {
			type: 'boolean',
			description: 'Whether the vehicle service automatically renews after it expires (web app only).'
		},
		redemptionQuantity: {
			type: 'number',
			description: 'The maximum number of times that the vehicle service can be redeemed. Is Redemption limited by Quantity.'
		},
		redemptionDays: {
			type: 'number',
			description: 'The number of days that the vehicle service can be redeemed for.'
		},
		eligibilityDays: {
			type: 'number',
			description: 'The number of days that the vehicle service is valid for.'
		}
	},
	required: ['id', 'group', 'location', 'title', 'serviceType', 'startDate', 'endDate', 'description', 'discount', 'discountType', 'redemptionQuantity', 'redemptionDays', 'eligibilityDays']
}