import JSX from '../../jsx.compiler';
import {ModuleLayout} from '../../types/module-layout.interface';
import {PipeType} from '../../types/types';

export const LOCATIONS_LAYOUT: ModuleLayout = {
	table: {
		tableColumns: [
			{
				key: '/name',
				label: 'NAME'
			},
			{
				key: '/address',
				label: 'ADDRESS',
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: (v, r) => JSX(<span>{[r.address?.address, r.address?.city, r.address?.state, r.address?.zip].filter(Boolean).join(', ')}</span>)
				}
			},
			{
				key: '/status',
				label: 'STATUS',
				pipe: [
					PipeType.Custom,
					PipeType.Sanitize
				],
				pipeArguments: {
					0: v => JSX(<app-e-status status={v} />)
				}
			}
		]
	}
};