import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { StateService } from '../services/state.service';

export function redirectGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot, next: RouterStateSnapshot) => {
    const state: StateService = inject(StateService);
    state.entryPath = next.url;
    return true;
  };
}
