import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import {
  Firestore,
  collection,
  doc,
  getDoc,
  getDocs,
} from '@angular/fire/firestore';
import {
  BehaviorSubject,
  Observable,
  defer,
  distinctUntilChanged,
  filter,
  forkJoin,
  from,
  map,
  of,
  shareReplay,
  switchMap,
  take,
} from 'rxjs';
import { Role } from '../types/role.interface';
import { TranslocoService } from '@ngneat/transloco';
import { DbService } from './db.service';
import { WhereFilter } from '../types/where-filter.interface';
import { FilterMethod } from '@definitions';
import { GlobalSettings } from '../interfaces/global-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor(
    private firestore: Firestore,
    private transloco: TranslocoService,
    private db: DbService
  ) {
    const language = localStorage.getItem('language');

    if (language) {
      this.transloco.setActiveLang(language);
    }

    this.transloco.langChanges$.subscribe(lang => {
      localStorage.setItem('language', lang);
    });

    // @ts-ignore
    this.translationsReady$ = this.transloco.events$.pipe(
      // @ts-ignore
      filter(e => e.type === 'translationLoadSuccess'),
      map(() => true),
      distinctUntilChanged(),
      shareReplay()
    );

    this.translationsReady$.subscribe();

    this.user$ = new BehaviorSubject(null);

    this.globalSettings$ = defer(() =>
      this.db.getDocument('settings', 'global').pipe(shareReplay(1))
    );
  }

  translationsReady$: Observable<boolean>;

  roles: Role[];

  /**
   * Initial navigation state for when the user
   * needs to be taken back to the login page
   * because of an expired token
   */
  entryPath: string;

  user$: BehaviorSubject<User | null>;
  staff$ = new BehaviorSubject<any>(null);

  /**
   * TODO:
   * Group types
   */
  group$ = new BehaviorSubject<any>(null);
  groups$: Observable<any[]>;
  location$ = new BehaviorSubject<any>(null);
  globalSettings$: Observable<GlobalSettings>;

  elementsRegistered = false;

  setPermissions() {
    if (localStorage.getItem('location')) {
      this.location$.next(localStorage.getItem('location'));
    }

    this.location$.subscribe(location => {
      localStorage.setItem('location', location);
    });

    return this.user$.pipe(
      filter(Boolean),
      switchMap(user => {
        this.setStaff(user.uid);
        return from(
          getDocs(collection(this.firestore, 'staff', user.uid, 'roles'))
        );
      }),
      switchMap(({ docs }) => {
        this.roles = docs.map(doc => doc.data()) as Role[];
        return this.setGroup();
      })
    );
  }

  setStaff(uid: string) {
    getDoc(doc(this.firestore, 'staff', uid))
      .then(docSnapshot => {
        if (docSnapshot.exists()) {
          const { firstName, lastName, email, location, role } =
            docSnapshot.data();
          const displayName = `${firstName} ${lastName}`;
          this.staff$.next({
            firstName,
            lastName,
            displayName,
            email,
            location,
            role,
          });
        } else {
          console.log('No such document!');
        }
      })
      .catch(error => {
        console.log('Error getting document:', error);
      });
  }

  setGroup() {
    const id = localStorage.getItem('group');
    const allowedGroups = this.groupFilters();

    if (!allowedGroups) {
      this.groups$ = defer(() => of([]));
      return of(null);
    }

    this.groups$ = defer(() =>
      this.db.getDocuments('auto-groups', null, null, null, allowedGroups).pipe(
        shareReplay(1),
        switchMap(docs =>
          forkJoin(
            docs
              .map(doc => ({
                id: doc.id,
                ...doc.data(),
              }))
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(doc =>
                this.db
                  .getDocument(
                    `auto-groups/${doc.id}/details`,
                    'auto-group-brand'
                  )
                  .pipe(
                    map(brand => ({
                      ...doc,
                      brand,
                    }))
                  )
              ) as any[]
          )
        )
      )
    );

    return this.groups$.pipe(
      filter(Boolean),
      take(1),
      map(groups => {
        const group = id ? groups.find(group => group.id === id) : groups[0];

        this.group$.next(group);

        if (group.brand.primaryColor) {
          const r = document.querySelectorAll('.light,.dark') as any;
          r.forEach(el =>
            el.style.setProperty('--primary-color', group.brand.primaryColor)
          );
        }

        return true;
      })
    );
  }

  groupFilters(): WhereFilter[] {
    if (
      this.roles.some(
        role =>
          (role.permissions.includes('*') ||
            role.permissions.includes('read-groups')) &&
          !role.group
      )
    ) {
      return [];
    }

    const allowedGroups = this.roles.reduce((acc, cur) => {
      if (cur.group) {
        acc.push(cur.group);
      }

      return acc;
    }, []);

    if (allowedGroups.length) {
      return [
        {
          key: '__name__',
          value: allowedGroups,
          operator: FilterMethod.In,
        } as WhereFilter,
      ];
    }

    return null;
  }
}
