import {JSONSchema7} from 'json-schema';

export const CUSTOMERS_SCHEMA: JSONSchema7 = {
	properties: {
		group: {
			type: 'string',
			description: `The customer's location auto group.`
		},
		location: {
			type: 'string',
			description: `The customer's primary location for vehicle maintenance.`
		},
		email: {
			type: 'string',
			description: `The email address of the customer.`
		},
		firstName: {
			type: 'string',
			description: `The customer's first name.`
		},
		lastName: {
			type: 'string',
			description: `The customer's last name.`
		},
		mobileNumber: {
			type: 'string',
			description: `The customer's mobile phone number.`
		},
		birthMonth: {
			type: 'number',
			description: `The customer's birth month.`
		},
		createdOn: {
			type: 'number',
			description: `The date and time that the customer record was created.`
		},
		vehicles: {
			type: 'array',
			description: `A list of vehicles owned by this customer.`	
		},
		vins: {
			type: 'array',
			description: `Added for search indexing. A list of VINs from vehicles assigned to this customer.`
		},
		licenses: {
			type: 'array',
			description: `Added for search indexing. A list of licenses from vehicles assigned to this customer.`
		},
		inviteId: {
			type: 'string',
			description: 'The id of the staff that invited the customer.'
		}
	},
	required: [
		'group',
		'location',
		'email',
		'firstName',
		'lastName',
		'mobileNumber',
		'birthMonth',
		'createdOn'
	]
}