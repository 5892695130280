export const environment = {
  name: 'Production',
  firebase: {
    apiKey: 'AIzaSyBQGCWV9C_ToshbaBmhgAlLVNO0-FGbt_4',
    authDomain: 'cardio-prod.firebaseapp.com',
    projectId: 'cardio-prod',
    storageBucket: 'cardio-prod.appspot.com',
    messagingSenderId: '359771006689',
    appId: '1:359771006689:web:2012d04e58c8d4bebb79a0',
    measurementId: 'G-BK3D939EF9',
  },
  paymentEndpoint: {
    url: 'https://ws.bluesnap.com/services/2/',
    script: 'https://pay.bluesnap.com/web-sdk/5/bluesnap.js',
  },
  firebaseEmulators: false,
  firebaseEmulatorsFunctions: false,
};
