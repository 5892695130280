import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import {
  connectFirestoreEmulator,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
import {
  connectStorageEmulator,
  getStorage,
  provideStorage,
} from '@angular/fire/storage';
import { environment } from '../../src/environments/environment';
import { getPerformance, providePerformance } from '@angular/fire/performance';

@NgModule({
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      const firestore = getFirestore();

      if (environment.firebaseEmulators) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }

      return firestore;
    }),
    provideStorage(() => {
      const storage = getStorage();

      if (environment.firebaseEmulators) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }

      return storage;
    }),
    provideAuth(() => {
      const auth = getAuth();

      if (environment.firebaseEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', {
          disableWarnings: true,
        });
      }

      return auth;
    }),
    provideFunctions(() => {
      const functions = getFunctions();

      if (environment.firebaseEmulatorsFunctions) {
        console.log('connecting to functions emulator');
        connectFunctionsEmulator(functions, 'localhost', 5000);
      }

      return functions;
    }),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
  ],
})
export class FirebaseModule {
  constructor(@Optional() @SkipSelf() parentModule: FirebaseModule) {
    if (parentModule) {
      throw new Error(
        'FirebaseModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(): ModuleWithProviders<FirebaseModule> {
    return {
      ngModule: FirebaseModule,
      providers: [],
    };
  }
}
