import {JSONSchema7} from 'json-schema';

export const AUTO_GROUPS_SCHEMA: JSONSchema7 = {
	properties: {
		name: {
			type: 'string',
			description: 'The name of the auto group.'
		},
		createdOn: {
			type: 'number',
			description: 'The date and time the auto group was created.'
		},
		termsAndConditions: {
			type: 'string',
			description: 'A file containing the terms and conditions for the auto group.'
		},
		status: {
			type: 'string',
			enum: [
				'active',
				'pending',
				'archived'
			]
		}
	},
	required: [
		'name',
		'createdOn',
		'termsAndConditions'
	]
}
