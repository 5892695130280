import { AUTO_GROUPS_MODULE } from './auto-groups/auto-groups.module';
import { AUTO_GROUP_ADDRESS_MODULE } from './auto-groups/details/auto-group-address.module';
import { AUTO_GROUP_BRAND_MODULE } from './auto-groups/details/auto-group-brand.module';
import { AUTO_GROUP_SETTINGS_MODULE } from './auto-groups/details/auto-group-settings.module';
import { LOCATIONS_BRAND_MODULE } from './locations/details/locations-brand.module';
import { LOCATIONS_MODULE } from './locations/locations.module';
import { SETTINGS_BENEFITS_MODULE } from './settings/benefits.module';
import { SETTINGS_GROUP_DEFAULTS_MODULE } from './settings/group-defaults.module';
import { STAFF_MODULE } from './staff/staff.module';
import { STAFF_ROLES_MODULE } from './staff/staff-roles.module';
import { CUSTOMERS_MODULE } from './customers/customers.module';
import { CUSTOMER_ADDRESS_MODULE } from './customers/details/customer-address.module';
import { CUSTOMER_PAYMENT_MODULE } from './customers/details/customer-payment.module';
import { BENEFIT_PLAN_DESCRIPTIONS_MODULE } from './customers/benefit-plan-descriptions/benefit-plan-descriptions.module';
import { BENEFIT_REDEMPTIONS_MODULE } from './customers/benefit-redemptions/benefit-redemptions.module';
import { VEHICLES_MODULE } from './vehicles/vehicles.module';
import { PROMOTIONS_MODULE } from './promotions/promotions.module';
import { SERVICES_MODULE } from './services/services.module';
import { BENEFIT_PLANS_MODULE } from './benefit-plans/benefit-plans.module';
import { CAMPAIGNS_MODULE } from './campaigns/campaigns.module';
import { DISPUTES_MODULE } from './disputes/disputes.module';
import { PAYOUTS_MODULE } from './payouts/payouts.module';
import { TRANSACTIONS_MODULE } from './transactions/transactions.module';

/**
 * Joined schemas for all of the modules
 */
export const MODULES = {
	'auto-groups': AUTO_GROUPS_MODULE,
	'auto-groups/details/auto-group-address': AUTO_GROUP_ADDRESS_MODULE,
	'auto-groups/details/auto-group-settings': AUTO_GROUP_SETTINGS_MODULE,
	'auto-groups/details/auto-group-brand': AUTO_GROUP_BRAND_MODULE,
	locations: LOCATIONS_MODULE,
	'locations/details/locations-brand': LOCATIONS_BRAND_MODULE,
	staff: STAFF_MODULE,
	'staff/staff-roles': STAFF_ROLES_MODULE,
	'settings/benefits': SETTINGS_BENEFITS_MODULE,
	'settings/group-defaults': SETTINGS_GROUP_DEFAULTS_MODULE,
	customers: CUSTOMERS_MODULE,
	'customers/details/customer-address': CUSTOMER_ADDRESS_MODULE,
	'customers/details/customer-payment': CUSTOMER_PAYMENT_MODULE,
	'customers/benefit-plan-descriptions': BENEFIT_PLAN_DESCRIPTIONS_MODULE,
	'customers/benefit-plan-redemptions': BENEFIT_REDEMPTIONS_MODULE,
	vehicles: VEHICLES_MODULE,
	promotions: PROMOTIONS_MODULE,
	services: SERVICES_MODULE,
	'benefit-plans': BENEFIT_PLANS_MODULE,
	campaigns: CAMPAIGNS_MODULE,
	transactions: TRANSACTIONS_MODULE,
	disputes: DISPUTES_MODULE,
	payouts: PAYOUTS_MODULE
};
