import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { StateService } from './services/state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private auth: Auth,
    private state: StateService
  ) {
    onAuthStateChanged(this.auth, user => {
      this.state.user$.next(user);
    });
  }
}
