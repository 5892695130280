import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { redirectGuard } from './guards/redirect.guard';
import { hasCodeGuard } from './modules/reset-password/guards/has-code/has-code.guard';
import { permissionsGuard } from './guards/permissions.guard';
import { SavingsCalculatorResolver } from './modules/savings-calculator/resolvers/savings-calculator.resolver';

const redirectUnauthorized = () => redirectUnauthorizedTo('/login');
const redirectLoggedInToDashboard = () => redirectLoggedInTo('/dashboard');

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./modules/login/login.component'),
    canActivate: [AuthGuard],
    data: {
      authGuardPipe: redirectLoggedInToDashboard,
    },
  },
  {
    path: 'trigger-password-reset',
    loadComponent: () =>
      import(
        './modules/trigger-password-reset/trigger-password-reset.component'
      ),
    canActivate: [AuthGuard],
    data: {
      authGuardPipe: redirectLoggedInToDashboard,
    },
  },
  {
    path: 'reset-password',
    canActivate: [hasCodeGuard()],
    loadComponent: () =>
      import('./modules/reset-password/reset-password.component'),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
    canActivate: [redirectGuard(), AuthGuard, permissionsGuard()],
    data: {
      authGuardPipe: redirectUnauthorized,
    },
  },
  {
    path: 'onboard',
    loadComponent: () => import('./modules/onboard/onboard.component'),
  },
  {
    path: 'terms/:store',
    loadComponent: () =>
      import('./modules/company-terms/company-terms.component'),
  },
  {
    path: 'purchase',
    loadComponent: () => import('./modules/purchase/purchase.component'),
  },
  {
    path: 'savings-calculator/:id',
    loadComponent: () =>
      import('./modules/savings-calculator/savings-calculator.component'),
    resolve: {
      calculator: SavingsCalculatorResolver,
    },
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./modules/privacy-policy/privacy-policy.component').then(
        mod => mod.PrivacyPolicyComponent
      ),
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
