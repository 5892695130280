import {JSONSchema7} from 'json-schema';

export const LOCATIONS_BRAND_SCHEMA: JSONSchema7 = {
	properties: {
		logo: {
			type: 'string'
		},
	},
	required: [
		'logo'
	]
}