import {JSONSchema7} from 'json-schema';

export const AUTO_GROUP_SETTINGS_SCHEMA: JSONSchema7 = {
	properties: {
		displayName: {
			type: 'string',
			description: 'The display name for the auto group.'
		},
    benefitPack: {
      type: 'number',
      description: 'The benefit pack for the auto group.'
    },
		serviceTypes: {
      type: 'array',
      items: {
        'type': 'string'
      },
      description: 'The service types for the auto group.'
    },
	},
	required: ['displayName', 'benefitPack', 'serviceTypes']
}