import JSX from '../../jsx.compiler';
import {PipeType} from '../../types/enums/pipe-type.enum';

export const TRANSACTIONS_LAYOUT = {
	sort: {
		active: 'createdOn',
		direction: 'desc'
	},
	table: {
		tableColumns: [
			{
				key: '/createdOn',
				label: 'TRANSACTION_DATE',
				pipe: [PipeType.Date]
			},
			{
				key: '/location',
				label: 'SERVICE_LOCATION',
				populate: {
					collection: 'locations',
					displayKey: 'name',
				}
			},
			{
				key: '/paymentMethod',
				label: 'PAYMENT_METHOD'
			},
			{
				key: '/customerName',
				label: 'CUSTOMER_NAME'
			},
			{
				key: '/cardholderName',
				label: 'CARDHOLDER_NAME'
			},
			{
				key: '/currency',
				label: 'CURRENCY'
			},
			{
				key: '/status',
				label: 'STATUS',
				pipe: [
					PipeType.Custom,
					PipeType.Sanitize
				],
				pipeArguments: {
					0: v => JSX(<app-e-status status={v} />)
				}
			},
			{
				key: '/amount',
				label: 'AMOUNT',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: (v, r) => JSX(<app-e-amount amount={v} currency={r.currency} />)
				}
			}
		]
	}
}