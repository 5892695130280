import {JSONSchema7} from 'json-schema';

export const STAFF_SCHEMA: JSONSchema7 = {
	properties: {
		firstName: {
			type: 'string',
			description: 'The first name of the staff member.'
		},
		lastName: {
			type: 'string',
			description: 'The last name of the staff member.'
		},
		email: {
			type: 'string',
			description: 'The email address of the staff member.'
		},
		createdOn: {
			type: 'number',
			description: 'The date and time the staff member was created.'
		},
		mobileNumber: {
			type: 'string',
			description: 'The mobile number of the staff member.'
		},
		externalId: {
			type: 'string',
			description: 'An external ID of the staff member.'
		},
		group: {
			type: 'string',
			description: 'The document id of group the staff member belongs to.'
		},
		location: {
			type: 'string',
			description: 'The document id of the location the staff member belongs to.'
		}
	},
	required: [
		'firstName',
		'lastName',
		'email',
	]
}