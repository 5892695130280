import {JSONSchema7} from 'json-schema';

export const CUSTOMER_ADDRESS_SCHEMA: JSONSchema7 = {
	properties: {
    address: {
      type: 'string',
      description: `The customer's address.`
    },
    city: {
      type: 'string',
      description: `The customer's city.`
    },
    state: {
      type: 'string',
      description: `The customer's state.`
    },
    zip: {
      type: 'string',
      description: `The customer's zip code.`
    },
    country: {
      type: 'string',
      description: `The customer's country.`
    },
    province: {
      type: 'string',
      description: `The customer's province if in Canada.`
    }
  },
  required: ['address', 'city', 'state', 'zip', 'country']
}